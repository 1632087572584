import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Box, SimpleGrid, GridItem } from '@chakra-ui/react';
import { FaAngleDoubleRight } from 'react-icons/fa';

import CanonicalLink from 'timelesstime-ui/components/canonical-link';
import HomeKnowledgebaseCard from '../home-knowledgebase-card';

export default () => {
  const {
    top3Knowledge: { nodes: top3Knowledge },
  } = useStaticQuery(graphql`
    query Top3Knowledge {
      top3Knowledge: allContentfulKnowledge(
        limit: 3
        sort: { fields: publishDate, order: DESC }
        filter: { slug: { ne: null } }
      ) {
        nodes {
          id
          title
          slug
          title
          knowledgeType
          createdAt
          updatedAt
          publishDate
          metaDescription
          videoUrl
          metaDescription
          jsonLdFeaturedImage: featuredImage {
            ...JsonLdFeaturedImage
          }
          jsonLdThumbnailImage: featuredImage {
            ...JsonLdThumbnailImage
          }
          authors {
            id
            firstName
            lastName
            email
            jobTitle
          }
          fields {
            path
            url
            plaintextContent
            readingTimeMins
            readingTimeText
            writtenAt
            isNew
            keywords
            excerptHtml
          }
          homeFeaturedImage: featuredImage {
            title
            gatsbyImageData(
              height: 200
              placeholder: BLURRED
              layout: CONSTRAINED
            )
          }
        }
      }
    }
  `);
  if (top3Knowledge.length < 1) {
    return null;
  }
  return (
    <>
      <SimpleGrid columns={[2, 2, 3]} mt={8} mb={4} spacing={6}>
        {top3Knowledge.map((knowledgeNode, index) => (
          <HomeKnowledgebaseCard
            key={knowledgeNode.id}
            knowledgebaseArticle={knowledgeNode}
            display={index >= 2 ? ['none', 'none', 'none', 'flex'] : 'flex'}
          />
        ))}
      </SimpleGrid>
      <Box textAlign="right">
        <CanonicalLink color="orange.500" to="/knowledgebase/">
          All knowledgebase pages <FaAngleDoubleRight />
        </CanonicalLink>
      </Box>
    </>
  );
};
