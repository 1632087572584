import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import format from 'date-fns/format';
import { Box, Flex, Text, HStack } from '@chakra-ui/react';

import CanonicalLink from 'timelesstime-ui/components/canonical-link';
import NewBadge from 'timelesstime-ui/components/new-badge';
import Heading from 'timelesstime-ui/components/heading';
import TypeBadge from '../type-badge';
import KnowledgeStructuredData from '../structured-data/knowledge';

const HomeKnowledgebaseCard = ({ knowledgebaseArticle, ...props }) => (
  <Flex
    as="article"
    flexDirection="column"
    border="1px solid"
    borderColor="gray.100"
    borderRadius="sm"
    justifyContent="flex-start"
    sx={{
      '.gatsby-image-wrapper img': {
        borderRadius: 'sm',
        borderBottomRadius: 'none',
      },
    }}
    {...props}
  >
    <KnowledgeStructuredData
      slug={knowledgebaseArticle.slug}
      knowledgeType={knowledgebaseArticle.knowledgeType}
      description={knowledgebaseArticle.metaDescription}
      title={knowledgebaseArticle.title}
      createdAt={new Date(knowledgebaseArticle.createdAt)}
      writtenAt={new Date(knowledgebaseArticle.fields.writtenAt)}
      updatedAt={new Date(knowledgebaseArticle.updatedAt)}
      plaintextContent={knowledgebaseArticle.fields.plaintextContent}
      authors={knowledgebaseArticle.authors}
      videoUrl={knowledgebaseArticle.videoUrl}
      thumbnailImage={knowledgebaseArticle.jsonLdThumbnailImage}
      featuredImage={knowledgebaseArticle.jsonLdFeaturedImage}
      readingTimeMins={knowledgebaseArticle.fields.readingTimeMins}
      shortMeta
    />

    <GatsbyImage
      image={getImage(knowledgebaseArticle.homeFeaturedImage)}
      alt={knowledgebaseArticle.homeFeaturedImage.title}
    />

    <Flex
      mt={2}
      px={4}
      mb={4}
      flexGrow="1"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <Flex
        as="header"
        flexGrow="1"
        flexDirection="column"
        justifyContent="flex-start"
      >
        <HStack as="aside" spacing={1} mt={2}>
          <TypeBadge
            type={knowledgebaseArticle.knowledgeType}
            title={knowledgebaseArticle.knowledgeType}
          />
          <NewBadge
            isNew={
              knowledgebaseArticle.fields.isNew ||
              knowledgebaseArticle.fields.isRecent
            }
          />
        </HStack>

        <Heading as="h1" fontSize="xl" my={2}>
          <CanonicalLink to={knowledgebaseArticle.fields.path}>
            {knowledgebaseArticle.title}
          </CanonicalLink>
        </Heading>
        <Text
          fontSize="sm"
          color="gray.600"
          mb={4}
          flexGrow="1"
          display="flex"
          flexDir="column"
          justifyContent="flex-end"
        >
          Written{' '}
          {format(
            new Date(knowledgebaseArticle.fields.writtenAt),
            'do MMMM yyyy',
          )}
          .{' '}
          {knowledgebaseArticle.fields.readingTimeText && (
            <>{knowledgebaseArticle.fields.readingTimeText}</>
          )}
        </Text>
      </Flex>

      <Box as="section">{knowledgebaseArticle.metaDescription}</Box>
    </Flex>

    <Box
      as="footer"
      px={4}
      py={2}
      bg="gray.50"
      borderTop="1px solid"
      borderColor="gray.100"
    >
      <CanonicalLink color="orange.500" to={knowledgebaseArticle.fields.path}>
        Read the Knowledgebase article
      </CanonicalLink>
    </Box>
  </Flex>
);

export default HomeKnowledgebaseCard;
