import React from 'react';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { Box, Flex, Stack, Text, Container } from '@chakra-ui/react';
import { FaUsers, FaSitemap, FaGraduationCap, FaStar } from 'react-icons/fa';

import SEO from 'timelesstime-ui/components/seo';
import CallMeBack from 'timelesstime-ui/components/call-me-back';
import Heading from 'timelesstime-ui/components/heading';
import ButtonLink from 'timelesstime-ui/components/button-link';
import { Organization as OrganizationStructuredData } from 'timelesstime-ui/components/json-ld';
import HomeHeroContent from 'timelesstime-ui/components/home-hero-content';
import BgImage from 'timelesstime-ui/components/bg-image';
import PageLayout from '../components/layout';
import JumbotronFeaturedEvent from '../components/jumbotron-featured-event';
import Top3Knowledge from '../components/top-3-knowledge';

const getFAIcon = (icon, props) => {
  switch (`${icon}`.toLowerCase()) {
    case 'users':
      return <FaUsers {...props} />;
    case 'sitemap':
      return <FaSitemap {...props} />;
    case 'graduationcap':
      return <FaGraduationCap {...props} />;
    default:
      return <FaStar {...props} />;
  }
};

const IndexPage = ({
  data: {
    page,
    page: {
      heroTitle,
      heroImage,
      heroPrimaryCallToActionText,
      heroPrimaryCallToActionUrl,
      heroSecondaryCallToActionText,
      heroSecondaryCallToActionUrl,
      quotes,
      fields: { heroTextHtml },
    },
    jsonLdThumbnailImage,
    featuredEvent,
  },
}) => (
  <PageLayout>
    <SEO
      title={page.title}
      keywords={page.fields.keywords}
      description={page.metaDescription}
      canonicalPath="/"
      thumbnail={jsonLdThumbnailImage}
    />
    <OrganizationStructuredData />

    <BgImage bg={getImage(page.splashImage)} preload adaptiveLoading>
      <Heading
        as="h1"
        m={0}
        fontSize={['3xl', '3xl', '4xl', '5xl']}
        maxW="1000px"
        w="90vw"
        color="white"
        textAlign="center"
        marginX="auto"
        paddingTop={[8, 8, 20, 24]}
      >
        {page.strapline}
      </Heading>
      <Heading
        as="h2"
        fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
        maxW="1000px"
        w="90vw"
        color="white"
        textAlign="center"
        marginX="auto"
        marginTop={8}
      >
        {page.leadInText}
      </Heading>

      <Container maxW="container.lg">
        <Flex flexDir={['column-reverse', 'column-reverse', 'column']} mt={8}>
          <Stack
            direction={['column', 'column', 'row']}
            justify="center"
            align="center"
            spacing={[4, 4, 4, 8]}
            mb={[8, 8, 0]}
          >
            {page.usps.map((usp) => (
              <Flex
                key={usp.text}
                textAlign="center"
                flexDir="column"
                justifyItems="center"
              >
                <Text
                  display="inline"
                  fontSize={['7xl', '7xl', '8xl']}
                  color="white"
                  textAlign="center"
                >
                  {getFAIcon(usp.icon)}
                </Text>
                <ButtonLink
                  to={usp.url}
                  height="auto"
                  py={2}
                  whiteSpace="wrap"
                  colorScheme="orange"
                  variant="solid"
                  size="lg"
                  h={[12, 12, 14]}
                  minW={[12, 12, 14]}
                  fontSize={['lg', 'lg', '2xl']}
                  px={[6, 6, 8]}
                >
                  {usp.text}
                </ButtonLink>
              </Flex>
            ))}
          </Stack>

          <Box my={12} textAlign="center">
            <CallMeBack
              size="md"
              h={[10, 10, 12]}
              minW={[10, 10, 12]}
              fontSize={['md', 'md', 'lg']}
              px={[4, 4, 6]}
              callBackText="Have a question? We'll call you back"
            />
          </Box>
        </Flex>
      </Container>
    </BgImage>
    <Container as="section" maxW="container.lg">
      <HomeHeroContent
        heroTitle={heroTitle}
        heroImage={heroImage}
        heroTextHtml={heroTextHtml}
        heroPrimaryCallToActionUrl={heroPrimaryCallToActionUrl}
        heroPrimaryCallToActionText={heroPrimaryCallToActionText}
        heroSecondaryCallToActionUrl={heroSecondaryCallToActionUrl}
        heroSecondaryCallToActionText={heroSecondaryCallToActionText}
        quotes={quotes}
      />
    </Container>
    <Box as="aside">
      <JumbotronFeaturedEvent event={featuredEvent?.nodes?.[0]} />
    </Box>
    <Container maxW="container.lg">
      <Top3Knowledge />
    </Container>
  </PageLayout>
);

export const query = graphql`
  query HomePageQuery($dateToday: Date!) {
    jsonLdThumbnailImage: file(name: { eq: "TimelessTime-Logo-Square" }) {
      childImageSharp {
        gatsbyImageData(width: 200, placeholder: BLURRED, layout: FIXED)
      }
    }
    page: contentfulHomePage(slug: { eq: "home" }) {
      title
      metaDescription
      splashImage {
        ...SplashImage
      }
      strapline
      leadInText
      usps {
        text
        icon
        url
      }
      heroTitle
      heroImage {
        title
        gatsbyImageData(width: 250, placeholder: BLURRED, layout: CONSTRAINED)
      }
      heroPrimaryCallToActionText
      heroPrimaryCallToActionUrl
      heroSecondaryCallToActionText
      heroSecondaryCallToActionUrl
      quotes {
        id
        person
        position
        company
        # get raw text here since we need it for structured data
        text {
          internal {
            content
          }
        }
        fields {
          textHtml
        }
      }
      fields {
        keywords
        heroTextHtml
      }
    }
    featuredEvent: allContentfulEventInstance(
      limit: 1
      sort: { fields: startDate, order: ASC }
      filter: {
        type: { in: ["Webinar", "Workshop"] }
        startDate: { gte: $dateToday }
      }
    ) {
      nodes {
        id
        title
        slug
        type
        metaDescription
        title
        locationName
        address
        ticketUrl
        informationUrl
        isSoldOut
        isOnline
        maxTicketPrice
        minTicketPrice
        ticketUrl
        latLng {
          lat
          lon
        }
        startDate
        endDate
        isPartOfSeries
        positionInSeries
        numberOfEventsInSeries
        createdAt
        featuredImage {
          ...FeaturedImage
        }
        jsonLdFeaturedImage: featuredImage {
          ...JsonLdFeaturedImage
        }
        jsonLdThumbnailImage: featuredImage {
          ...JsonLdThumbnailImage
        }
        speakers {
          id
          firstName
          lastName
          email
          jobTitle
        }
        eventbriteEventId
        fields {
          path
          url
          writtenAt
          isNew
          keywords
          excerptHtml
        }
      }
    }
  }
`;

export default IndexPage;
