import React from 'react';
import isDate from 'date-fns/isDate';
import isPast from 'date-fns/isPast';
import format from 'date-fns/format';
import differenceInHours from 'date-fns/differenceInHours';
import { getSrc, getImage } from 'gatsby-plugin-image';

import PropTypes from 'prop-types';

import httpsUrl from 'timelesstime-ui/utils/https-url';
import JsonLd, { organizationId } from 'timelesstime-ui/components/json-ld';

export const type = () => 'BusinessEvent';
export const id = (slug) =>
  `https://timelesstime.co.uk/events/${slug}#${type()}/`;

const EventStructuredData = ({
  slug,
  title,
  speakers,
  description,
  startDate,
  endDate,
  locationName,
  address,
  latLng,
  isFree,
  isOnline,
  ticketUrl,
  createdAt,
  minTicketPrice,
  maxTicketPrice,
  isSoldOut,
  featuredImage,
}) => {
  const featuredImageSrc = getSrc(getImage(featuredImage));
  return (
    <JsonLd
      data={{
        '@context': 'http://schema.org',
        '@type': type(),
        '@id': id(slug),
        organizer: {
          '@id': organizationId(),
          name: 'TimelessTime',
          url: 'https://timelesstime.co.uk',
        },
        performer: speakers.map((speaker) => ({
          '@type': 'Person',
          name: `${speaker.firstName} ${speaker.lastName}`,
          givenName: speaker.firstName,
          familyName: speaker.lastName,
          jobTitle: speaker.jobTitle,
          email: speaker.email,
        })),
        name: title,
        url: `https://timelesstime.co.uk/events/${slug}/`,
        mainEntityOfPage: `https://timelesstime.co.uk/events/${slug}/`,
        description,
        image: httpsUrl(featuredImageSrc),
        inLanguage: {
          '@type': 'Language',
          name: 'English',
          alternateName: 'en',
        },
        audience: {
          '@type': 'BusinessAudience',
          numberOfEmployees: {
            '@type': 'QuantitativeValue',
            minValue: '1',
            maxValue: '250',
          },
        },
        doorTime: isDate(startDate) ? format(startDate, 'HH:mm:ss') : null,
        duration:
          // eslint-disable-next-line no-nested-ternary
          isDate(startDate) && isDate(endDate)
            ? differenceInHours(endDate, startDate) === 0
              ? 'P6H'
              : `P${differenceInHours(endDate, startDate)}H`
            : null,
        startDate: isDate(startDate) ? format(startDate, 'yyyy-MM-dd') : null,
        endDate: isDate(endDate) ? format(endDate, 'yyyy-MM-dd') : null,
        isAccessibleForFree: isFree,
        eventAttendanceMode: isOnline
          ? 'https://schema.org/OnlineEventAttendanceMode'
          : 'https://schema.org/OfflineEventAttendanceMode',
        eventStatus: 'https://schema.org/EventScheduled',
        location: {
          '@type': 'Place',
          name: locationName,
          address,
          ...(latLng && {
            geo: {
              '@type': 'GeoCoordinates',
              latitude: latLng.lat,
              longitude: latLng.lon,
            },
          }),
        },
        ...(ticketUrl && {
          offers: {
            '@type': 'Offer',
            url: ticketUrl,
            category: 'Primary',
            validFrom: format(createdAt, 'yyyy-MM-dd'),
            availabilityStarts: format(createdAt, 'yyyy-MM-dd'),
            availability: [
              'http://schema.org/OnlineOnly',
              isSoldOut || (startDate !== undefined && isPast(startDate))
                ? 'http://schema.org/SoldOut'
                : 'http://schema.org/InStock',
            ],
            priceSpecification: {
              '@type': 'PriceSpecification',
              minPrice: minTicketPrice,
              maxPrice: maxTicketPrice,
            },
            price: minTicketPrice,
            priceCurrency: 'GBP',
          },
        }),
      }}
    />
  );
};

EventStructuredData.defaultProps = {
  ticketUrl: undefined,
  isSoldOut: false,
  latLng: undefined,
  minTicketPrice: undefined,
  maxTicketPrice: undefined,
  shortMeta: false,
};

EventStructuredData.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  speakers: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      jobTitle: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }),
  ).isRequired,
  description: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  createdAt: PropTypes.instanceOf(Date).isRequired,
  isFree: PropTypes.bool.isRequired,
  isOnline: PropTypes.bool.isRequired,
  isSoldOut: PropTypes.bool,
  minTicketPrice: PropTypes.number,
  maxTicketPrice: PropTypes.number,
  locationName: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  latLng: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lon: PropTypes.number.isRequired,
  }),
  ticketUrl: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  thumbnailImage: PropTypes.object.isRequired,
  featuredImage: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  shortMeta: PropTypes.bool,
};

export default EventStructuredData;
