import React from 'react';
import format from 'date-fns/format';
import { Box, Flex, Text } from '@chakra-ui/react';
import Html from 'timelesstime-ui/components/html';
import Jumbotron from 'timelesstime-ui/components/jumbotron';
import Heading from 'timelesstime-ui/components/heading';
import ButtonLink from 'timelesstime-ui/components/button-link';
import CanonicalLink from 'timelesstime-ui/components/canonical-link';

import EventStructuredData from '../structured-data/event';

const startDateHtml = ({ startDate, locationName }) => {
  if (startDate && locationName) {
    return (
      <>
        {format(new Date(startDate), 'do MMMM')} | {locationName}
      </>
    );
  }
  if (startDate) {
    return <>{format(new Date(startDate), 'do MMMM')}</>;
  }
  return <>{locationName}</>;
};

const callToActionText = ({
  ticketUrl,
  informationUrl,
  ticketText,
  informationText,
}) => {
  if (ticketUrl) {
    return (
      <CanonicalLink
        textDecoration="none"
        fontWeight="bold"
        fontSize="lg"
        to={ticketUrl}
      >
        {ticketText}
      </CanonicalLink>
    );
  }
  if (informationUrl) {
    return (
      <CanonicalLink
        textDecoration="none"
        fontWeight="bold"
        fontSize="lg"
        to={informationUrl}
      >
        {informationText}
      </CanonicalLink>
    );
  }
  return null;
};

const callToActionButton = ({
  ticketUrl,
  informationUrl,
  ticketText,
  informationText,
  fallbackText,
}) => {
  if (ticketUrl) {
    return (
      <ButtonLink
        mt={4}
        colorScheme="orange"
        color="tt.900"
        fontSize="lg"
        to={ticketUrl}
      >
        {ticketText}
      </ButtonLink>
    );
  }
  if (informationUrl) {
    return (
      <ButtonLink
        mt={4}
        colorScheme="orange"
        color="tt.900"
        fontSize="lg"
        to={informationUrl}
      >
        {informationText}
      </ButtonLink>
    );
  }
  if (fallbackText) {
    return (
      <Text as="i" mt={4} fontSize="lg" color="gray.50">
        {fallbackText}
      </Text>
    );
  }
  return null;
};

export const JumbotronFeaturedEvent = ({ event }) => {
  if (typeof event !== 'object' || !event.title) {
    return null;
  }
  return (
    <Jumbotron>
      <EventStructuredData
        slug={event.slug}
        title={event.title}
        speakers={event.speakers}
        description={event.metaDescription}
        startDate={new Date(event.startDate)}
        endDate={new Date(event.endDate)}
        locationName={event.locationName}
        address={event.address}
        latLng={event.latLng}
        isFree={
          (event.minTicketPrice === undefined || event.minTicketPrice === 0) &&
          (event.maxTicketPrice === undefined || event.maxTicketPrice === 0)
        }
        isOnline={event.isOnline}
        ticketUrl={event.ticketUrl}
        createdAt={new Date(event.createdAt)}
        minTicketPrice={event.minTicketPrice}
        maxTicketPrice={event.maxTicketPrice}
        isSoldOut={false}
        thumbnailImage={event.jsonLdThumbnailImage}
        featuredImage={event.jsonLdFeaturedImage}
        shortMeta
      />

      <Flex direction="column">
        <Heading as="h1" fontSize="3xl" mb={2} color="white">
          {event.type}: {event.title}
        </Heading>

        <Heading as="h2" fontSize="2xl" mt={2} color="white">
          {startDateHtml({
            startDate: event.startDate,
            locationName: event.locationName,
          })}
        </Heading>

        <Box mt={4} mb={4}>
          {callToActionText({
            ticketUrl: event.ticketUrl,
            informationUrl: event.informationUrl,
            ticketText: 'Sign up today',
            informationText: 'Get more information',
          })}
        </Box>

        <Html mb={4} source={event.fields.excerptHtml} headerLevelStart={2} />

        <Box mb={4}>
          {callToActionButton({
            ticketUrl: event.ticketUrl,
            informationUrl: event.informationUrl,
            ticketText: 'Book your place today',
            informationText: 'Find out more',
            fallbackText: 'Details to be confirmed',
          })}
        </Box>
      </Flex>
    </Jumbotron>
  );
};

export default JumbotronFeaturedEvent;
