import React from 'react';
import { Box, Container } from '@chakra-ui/react';

const Jumbotron = ({ children, ...props }) => (
  <Box color="white" bg="gray.600" w="100%" py={6} px={4} {...props}>
    <Container
      display="flex"
      maxW="container.lg"
      textAlign="center"
      justifyContent="center"
    >
      {children}
    </Container>
  </Box>
);

export default Jumbotron;
